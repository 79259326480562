import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Home from "./Home.js";
import Studio from "./Studio.js";
import StudioSpec from "./StudioSpec.jsx";
import PoliticaDePrivacidade from "./PoliticaDePrivacidade.js";

const Router = () => {
   return(
       <BrowserRouter>
            <Routes>
             <Route element = { <Home/> }  path="/" exact />
             <Route element = { <Studio/> }  path="/studios/:pagina" exact />
             <Route element = { <StudioSpec/>} path="/studios/specific/:studio" exact/> 
             <Route element = { <PoliticaDePrivacidade/> } path="/politicaDePrivacidade" exact />
            </Routes>
       </BrowserRouter>
   )
}

export default Router;