import React from "react";

import "./style/localizacao.css";

const LocalizacaoPrivilegiada = () => {
    return (
        <div className="localizacao">
            <div className="conteudo_loc" data-aos="fade-right" data-aos-duration="1000">
                <h3>LOCALIZAÇÃO </h3>
                <h4> PRIVILEGIADA </h4>
                <p>
                 Localizado em uma região nobre de Curitiba, perto de restaurantes e diversos outros pontos valiosos. 
                </p>
            </div>
            <iframe data-aos="fade-up" data-aos-duration="1000" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.4272742268863!2d-49.28670798537281!3d-25.42397498379127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce3f8736d9b07%3A0x9ec94515fa6be44e!2sRua%20Desembargador%20Isa%C3%ADas%20Bevilaqua%2C%20213%20-%20Merc%C3%AAs%2C%20Curitiba%20-%20PR%2C%2080430-040!5e0!3m2!1spt-BR!2sbr!4v1672611722935!5m2!1spt-BR!2sbr"></iframe>
        </div>
    )
}   

export default LocalizacaoPrivilegiada;