import React, { Component } from "react";
import Slider from "react-slick";
import "./style/carrousel.css";


export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="Carrou">
        <Slider {...settings}>
        <div>
            <img className="Carrousel_image" src="hall01.jpg"></img>
            <p>Hall</p>
          </div>
          <div>
            <img className="Carrousel_image" src="hall02.jpg"></img>
            <p>Hall</p>
          </div>
          <div>
            <img className="Carrousel_image" src="ESPAÇO GOURMET.jpg"></img>
            <p> Espaço Gourmet</p>
          </div>
       
          <div>
            <img className="Carrousel_image" src="1.jpg"></img>
            <p> Coworking </p>
          </div>
          <div>
            <img className="Carrousel_image" src="4.jpg"></img>
            <p> Coworking </p>
          </div>
          <div>
            <img className="Carrousel_image" src="JACUZZI 001 (1).jpg"></img>
            <p> SPA </p>
          </div>
          <div>
          <img className="Carrousel_image" src="ACADEMIA.jpg"></img>
            <p> Espaço fitness </p>
          </div>
          <div>
          <img className="Carrousel_image" src="001 (3).jpg"></img>
            <p> Lavanderia</p>
          </div> 
        </Slider>
      </div>
    );
  }
}