import React from "react";

import "./style/localizacao.css";

const ConcreSobre = () => {
    return (
        <div className="localizacao concre_sobre" style={{backgroundColor:'linear-gradient(to left,#C59776, #8B6F54)'}}>
            <div className="conteudo_loc" style={{paddingTop:"2rem"}}data-aos="fade-right" data-aos-duration="1000">
                <h3>CONSTRUÇÃO </h3>
                <h4 class="incorpo"style={{backgroundColor:"#e6eeef", color: "#8B6F54", textShadow:'none' }}>E INCORPORAÇÃO </h4>
                <p id="textoConcre">
                Com mais de 15 anos de experiência, atuando na construção e 
                incorporação de edifícios na capital paranaense e região, 
                primamos pela seriedade no trato com o cliente, numa relação
                de proximidade, desenvolvendo projetos com arquitetura exclusiva,
                tecnológicos, voltados à alta rentabilidade para o investidor. 
                A satisfação na aquisição de nossas unidades imobiliárias, se 
                reflete no alto índice de clientes que voltam a negociar conosco. 
                Com satisfação afirmamos que se tornou frequente a busca de nossos
                clientes por uma segunda, terceira, quarta unidade do nosso portfólio,
                 o que nos impulsiona cada vez mais, 
                fortalecendo o nosso sentimento de que estamos no caminho certo.
                </p>
            </div>
            <div style={{display:'flex', flexDirection: "column", alignContent:'space-around'}}>
            <div style={{margin: 'auto'}} className="cocre_foto" data-aos="fade-up" data-aos-duration="1300">            
                <img src="./concrezatto_logo.png" />
                <h5 className="concrezatto_titulo">CONCREZATTO</h5>
            </div>
            </div>
        </div>
    )
}   

export default ConcreSobre;