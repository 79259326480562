import { flexbox } from "@mui/system";
import React, {useState, useEffect} from "react";

import {useParams} from "react-router";

import { Link } from "react-router-dom";

import Card from "./Card";
import "./style/studios.css";
import image from "./style/1/2.jpg"
import image2 from "./style/2/1.jpg"
import image3 from "./style/3/1.jpg"
import image4 from "./style/4/1.jpg"
import image5 from "./style/5/1.jpg"
import image6 from "./style/6/1.jpg"
import image7 from "./style/7/2.jpg"
import image8 from "./style/8/1.jpg"
import image9 from "./style/9/1.jpg"
import image10 from "./style/10/1.jpg"
import image11 from "./style/11/1.jpg"
import image12 from "./style/12/1.jpg"

const Studio = () =>  {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(data);
    const [loading, setLoading] = useState(false);
    let [test, setTest ] = useState(true);
    let componentMounted = true; 
    let paginaP = useParams();

    let page;

    useEffect(() => {
    console.log(paginaP.pagina)
    }, []);

    

  
   
    
    const Loading = () => {
        return (
            <>
                Carregando...
            </>
        )
    }

    const ShowStudios = () => {

        if (paginaP.pagina == 0) {
        return (
           
        <div className="STUDIOS">
            <div style={{flex:"row"}}>
              
            <a style={{color: "white",  textDecoration: "none", border:"1.5px solid white", fontFamily:"Montserrat", marginTop:"1rem"}} className="custom-btn btn-15 aos-init aos-animate" href="/"> VOLTAR</a>
            <h1>STUDIOS </h1>
            </div>
           
            <div className="studioG">
  
            <Link to={"/studios/specific/1"} style={{ textDecoration: 'none' }}>
                                <Card 
                                image={image}
                                title={"studio 101"}
                                location=""
                                description=""
                                //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                //     +
                                //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                // } 
                                studio={"ver studio"}
                                color={"2px solid #C59776"}
                                textColor={""}
                                />
                                </Link>
                             
                              
                                    {/* <Link to={"/studios/specific/4"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image3}
                                    title={"studio 104"}
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studio"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link> */}
                              
                                    <Link to={"/studios/specific/3"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image3}
                                    title={"studios 103 | 104"}//| 205 | 206
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studio"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link>
                              
                                    <Link to={"/studios/specific/5"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image5}
                                    title={"studio 201"}
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studio"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link>
                              
                                    <Link to={"/studios/specific/2"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image2}
                                    title={"studio 204"}
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studios"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link>
                               
                                    <Link to={"/studios/specific/7"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image7}
                                    title={"studio 301"}
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studio"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link>
                                    <div>
                                    {/* <Link to={"/studios/specific/8"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image8}
                                    title={"studios 303 | 403"}
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studios"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link> */}
                                </div>
                             
            </div>
        </div>
        )} else
        if (paginaP.pagina == 1) {
            return (
               
            <div className="STUDIOS">
                <div style={{flex:"row"}}>
                <a style={{color: "white",  textDecoration: "none", border:"1.5px solid white", fontFamily:"Montserrat", marginTop:"1rem"}} className="custom-btn btn-15 aos-init aos-animate" href="/"> VOLTAR</a>
                <h1>STUDIOS </h1>
                </div>
               
                <div className="studioG">
                   
{/*                           
                                <div>
                                    <Link to={"/studios/specific/7"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image7}
                                    title={"studios 302 | 402 | 502"}
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studios"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link>
                                    
                                </div> */}
                            
                                <div>
                                    <Link to={"/studios/specific/9"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image9}
                                    title={"studios 404 | 504"}
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studio"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link>
                                </div>
                                <div>
                                    <Link to={"/studios/specific/10"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image10}
                                    title={"studio 601"}
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studio"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link>
                                </div>
                                {/* <div>
                                    <Link to={"/studios/specific/11"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image11}
                                    title={"studio 702"}
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studios"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link>
                                </div> 
                                <div>
                                    <Link to={"/studios/specific/12"} style={{ textDecoration: 'none' }}>
                                    <Card 
                                    image={image12}
                                    title={"studio 703"}
                                    location=""
                                    description=""
                                    //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                    //     +
                                    //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                    // } 
                                    studio={"ver studios"}
                                    color={"2px solid #C59776"}
                                    textColor={""}
                                    />
                                    </Link>
                                </div> */}
                </div>
            </div>
            )}


    }

    return (
       <div>
           {<ShowStudios/>}
           <div style={{marginBottom:"1rem"}}>
           <h2>Página: </h2>
           <a href="/studios/0"style={{color: "white", fontSize:"23px", textDecoration:"none"}}>⇽ 1 | </a>
           <a href="/studios/1" style={{color: "white", fontSize:"23px", textDecoration:"none"}}> 2 ⇾</a>
      
           </div>   
       </div>
   )
}

export default Studio;