import React from "react";

import "./style/Header.css";
import { Link } from "react-router-dom";



const Header = () => {
    return (
       <div className="Header">
           <div className="Header_front">
               <nav>
                   <ul>
                       <li><a href="#AreasComuns">ÁREAS COMUNS</a></li>
                       <Link to="/studios/0"> <li><a>STUDIOS</a></li> </Link>
                       <li><a href="#Contato">CONTATO</a></li>
                   </ul>
                   <a href="http://concrezatto.com.br" className="cocreDad">
                   <div className="cocre">
                        <p>CONCREZATTO</p>
                        <img class="logo_concrezatto"src="concrezatto_logo.png" alt="concrezatto"/>
                    
                   </div>
                   </a>
               </nav>
               <div className="bottom">
                   <div className="santi_one" data-aos="fade-right" data-aos-duration="1000">
                        <img src="santi_logo.png" alt="santi studio residence"/>
                        <div class="container">
                            <p>estágio da obra: 95%</p>
                            <div class="progress-bar"></div>
                        </div>
                        <p className="apartir">Studios a partir de R$324.900,00,  <br/> com até 35m².</p>
                        <a className="custom-btn btn-15 aos-init aos-animate " id="botao-header" data-aos="fade-up" data-aos-duration="1300" href="https://api.whatsapp.com/send?phone=5541996480900">
                quero saber mais
            
              </a>
                   </div>
                   <div className="Header_text">
                       <p data-aos="fade-up"   
                          data-aos-duration="1300">

                           <b>SINTONIA </b><br/>
                           PERFEITA <br/>
                           PARA O <br/>
                           SEU <b>RITMO</b> <br/>
                           DE VIDA.
                       </p>
                   </div>
               </div>
           </div>
       
       <div className="float">
        <i className="fa fa-plus my-float"></i> 
        <a href="https://api.whatsapp.com/send?phone=5541996480900" class="float" ><img class="whats"src="whatsapp-logo_icon-icons.com_57054.png"/></a>
        <a href="https://www.facebook.com/concrezatto/" ><img class="face"src="facebook.png"/> </a>
        <a href="https://www.instagram.com/concrezatto_construtora/?igshid=OTJlNzQ0NWM%3D"><img class="insta"src="instagram.png"/> </a>
       </div>
       </div>
    )
}

export default Header;