import React from "react";

import Studios from "./Studios.jsx";

const Studio = () =>  {
    return (
        <div>
            <Studios/>
        </div>
    )
}

export default Studio;