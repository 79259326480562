import React from 'react';

import logo from './logo.svg';

import './App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Router from './components/Router.js';




function App() {
  return (
    <div className="App">
        <Router />
    </div>
  );
}

export default App;
