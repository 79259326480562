import React from "react";
import { Link } from "react-router-dom";
import "./style/sobrenos.css";

const Sobrenos = () => {
    return (
      <div className="sobrenos">
          <img src="exterior.png" alt="santi_exterior" data-aos="fade-right" data-aos-duration="1000"/>
          <div className="sobrenos_div">
            <h1 data-aos="fade-up" data-aos-duration="1300">SINTONIA
                PERFEITA   
                PARA O 
                SEU RITMO
                DE VIDA</h1>
            <p className="sobrenos_text" data-aos="fade-up" data-aos-duration="1600">
            O Santi foi projetado com studios flexíveis
            capazes de seguirem a sua harmonia. Aqui você encontra
            várias opções para escolher qual se encaixa à sua personalidade e rotina.

            </p>
            <Link to="/studios/0">
              <a className="custom-btn btn-15 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1300">
                ver studios
            
              </a>
            </Link>
          </div>
      </div>
    )
}

export default Sobrenos;