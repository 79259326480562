import React from "react";

import Responsive from "./MultipleItems";
import "./style/areascomuns.css";

const AreasComuns = () => {
    return (
        <div className="container_areas" id="AreasComuns">
            <div className="areas_comuns">
                <h2>ÁREAS COMUNS</h2>
                <Responsive />
            </div>
        </div>
    )
}

export default AreasComuns;