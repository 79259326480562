import React from "react";

import Header from "./Header.js";
import Sobrenos from "./Sobrenos.js";
import AreasComuns from "./AreasComuns.js";
import LocalizacaoPrivilegiada from "./LocalizacaoPrivilegiada.js";
import FormContato from "./formContato.js";
import Arquitetura from "./Arquitetura.js";
import ConcreSobre from "./ConcreSobre.js";

const Home = () => {
    return (
      <div>
          <Header/> 
          <Sobrenos/> 
          <AreasComuns/> 
          <LocalizacaoPrivilegiada/> 
          <Arquitetura/>
          <ConcreSobre/>
          <FormContato/>      
      </div>
    )
}

export default Home;
