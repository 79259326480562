import React, { useRef } from "react";

import "./style/formContato.css";

import { send } from 'emailjs-com';

const FormContato = () => {
      const form = useRef();
    
      const onSubmit = (e) => {
        e.preventDefault();
    
        send(
          'service_mxjpfr9',
          'template_q4avvqs',
          form.current,
          'user_9Ct6rnprjW7Uufix72OsD'
        )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            window.location.reload();
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
          
      };

    return (
       <div>
           <div className="container-f" id="Contato">
                <h8 data-aos="fade-up" data-aos-duration="1000">ENTRE EM CONTATO</h8>
            <div className="content">
                    <div className="content_info">
                        <p className="text_info" data-aos="fade-up" data-aos-duration="1500" >
                            <b style={{textTransform:'uppercase'}}>Plantão de Vendas</b> <br/>
                            Rua Desembargador Isaías Beviláqua 213 - Mercês<br/>
                            <br/>
                            <b style={{textTransform:'uppercase'}}>Entre em contato pelo WhatsApp</b> <br/>
                            <a href="https://api.whatsapp.com/send?phone=5541996480900" style={{color:'inherit'}}>41 99648.0900 </a><br/>
                            <br/>
                            <b style={{textTransform:'uppercase'}}>Siga-nos no Instagram</b> <br/>
                            <a style={{color:'inherit'}}href="https://www.instagram.com/concrezatto_construtora/?igshid=OTJlNzQ0NWM%3D">@concrezatto_construtora </a><br/>
                            <br/>
                            <b style={{textTransform:'uppercase'}}>Venda exclusiva</b> <br/>
                            Concrezatto Construtora<br/>
                        </p>
                    </div>
                    <form class="send-emails" onSubmit={onSubmit} data-aos="fade-up" data-aos-duration="2000">
                    <div className="field" tabindex="1">
                        <label for="username">
                            <i className="far fa-user"></i>Seu nome
                        </label>
                        <input class="nome" name="from_name"   type="text" placeholder="ex: joão" required/>
                    </div>
                    <div className="field" tabindex="2">
                        <label for="email">
                            <i className="far fa-envelope"></i>Seu email
                        </label>
                        <input name="reply_to" class="email"type="text"  placeholder="ex: email@dominio.com" required/>
                    </div>
                    <div className="field" tabindex="3">
                        <label for="message">
                            <i className="far fa-edit"></i>Deixe uma mensagem
                        </label>
                        <textarea name="message" placeholder="Escreva aqui" required></textarea>
                    </div>
                    <button type="submit">ENVIAR MENSAGEM</button>
                    
                    </form>
                    
            </div>
            <p style={{maxWidth: '60vw', textAlign: "justify", opacity: '50%'}}>
                Imagens meramente ilustrativas. Mobiliário/eletrônicos não inclusos. Possíveis
                 alterações de projeto podem ocorrer.  Consulte memorial descritivo do empreendimento.
                   Valores/condições sujeitos a alteração sem prévio aviso. Matrícula 57473 - 1ª Circunscrição de Curitiba.
            </p>
            <a href="/politicaDePrivacidade" style={{fontFamily:'Montserrat, sans-serif'}}>Política de Privacidade</a>
        </div>
       </div>
    )
}

export default FormContato;